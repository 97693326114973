import { isRight } from 'fp-ts/lib/Either';
import { ContentItem, decodeContentItem, PrepreparedContentItem } from 'polpeo-go-common/types/ContentItem';
import { decodeEmailItem, EmailItem, PrepreparedEmailItem } from 'polpeo-go-common/types/EmailItem';
import { Moment } from 'polpeo-go-common/types/Moment';
import { Page } from 'polpeo-go-common/types/Page';
import { ParticipantTeamAssignments } from 'polpeo-go-common/types/ParticipantAssignments';
import { ParticipantUser } from 'polpeo-go-common/types/ParticipantUser';
import { Simulation } from 'polpeo-go-common/types/Simulation';
import { StaffUser } from 'polpeo-go-common/types/StaffUser';
import { Team } from 'polpeo-go-common/types/Team';
import { Trigger } from 'polpeo-go-common/types/Trigger';
import {
    assoc,
    assocPath,
    dissoc,
    dissocPath,
    map,
    mapObjIndexed,
    mergeDeepRight,
    path,
    pathOr,
    reduce,
    reject,
    toPairs,
    values,
} from 'ramda';
import React, { FC, useEffect, useState } from 'react';
import { listSimulationsAndTeams } from '../../graphql/simulations/listSimulationsAndTeams';
import { listStaffUsers } from '../../graphql/staffusers/listStaffUsers';
import { decodeMoment } from '../../utils/decodeMoment';
import { decodeTrigger } from '../../utils/decodeTrigger';
import WithChildren from '../../utils/WithChildren';
import { FullPageSplashLoadingScreen } from '../patterns/FullPageSplashLoadingScreen';
import { AdminStateContext, SimulationContent } from './adminState';

// Simulation contains a few date types which are passed to us as strings by graphql
// use 'decode' to actually turn them into Date objects!
const decodeSimulation = (sim: unknown): Simulation => {
    const maybeSimulation = Simulation.decode(sim);
    if (isRight(maybeSimulation)) {
        return maybeSimulation.right;
    }
    throw new Error('Simulation was not the right shape');
};

const decodeStaffUser = (sim: unknown): StaffUser => {
    const maybeStaffUser = StaffUser.decode(sim);
    if (isRight(maybeStaffUser)) {
        return maybeStaffUser.right;
    }
    throw new Error('maybeStaffUser was not the right shape');
};

type SimulationUUID = string;
export const WithAdminState: FC<WithChildren> = ({ children }: WithChildren) => {
    const [simulations, setSimulations] = useState<Record<SimulationUUID, Simulation>>();
    const [teams, setTeams] = useState<Record<SimulationUUID, Record<string, Team>>>();
    const [staffUsers, _setStaffUsers] = useState<Record<string, StaffUser>>();
    const [participants, _setParticipants] = useState<Record<SimulationUUID, Record<string, ParticipantUser>>>({});
    const [teamAssignments, _setTeamAssignments] = useState<Record<SimulationUUID, ParticipantTeamAssignments>>({});
    const [simulationContent, _setSimulationContent] = useState<Record<SimulationUUID, SimulationContent>>({});

    // Simulations State
    const setSimulation = (sim: Simulation) => {
        const simulation = decodeSimulation(sim);
        setSimulations(assoc(simulation.uuid, simulation));
    };
    const addSimulation = (sim: Simulation) => {
        setSimulation(sim);
        setTeams({ ...teams, [sim.uuid]: {} });
    };
    const removeSimulation = (sim: Simulation | string) => {
        setSimulations(dissocPath([typeof sim === 'string' ? sim : sim.uuid]));
        setTeams(dissocPath([typeof sim === 'string' ? sim : sim.uuid]));
        _setSimulationContent(dissocPath([typeof sim === 'string' ? sim : sim.uuid]));
    };

    // Teams State
    const setTeam = (team: Team) => {
        setTeams(assocPath([team.simulationUUID, team.uuid], team));
    };
    const removeTeam = (team: Team) => {
        setTeams(dissocPath([team.simulationUUID, team.uuid], teams));
    };

    // Staff Users
    const setStaffUsers = (updatedStaffUsers: Record<string, StaffUser>) => {
        const decodedStaffUsers = reduce(
            (acc, [staffUUID, staffUser]) => ({ ...acc, [staffUUID]: decodeStaffUser(staffUser) }),
            {} as Record<string, StaffUser>,
            toPairs(updatedStaffUsers),
        );
        _setStaffUsers(decodedStaffUsers);
    };

    // Participants and team assignments
    const setParticipants = (
        simulationUUID: string,
        updatedParticipants: Record<string, ParticipantUser> | undefined,
    ) => {
        const updatedParticipantMap = reduce(
            (acc, [participantUUID, participant]) => ({ ...acc, [participantUUID]: participant }),
            {} as Record<string, ParticipantUser>,
            toPairs(updatedParticipants || {}),
        );
        _setParticipants(assocPath([simulationUUID], updatedParticipantMap));
    };
    const setTeamAssignments = (simulationUUID: string, teamAssignments: ParticipantTeamAssignments | undefined) => {
        _setTeamAssignments(assocPath([simulationUUID], teamAssignments));
    };

    // Simulation Content State
    const setSimulationContent = (simulationUUID: string, content: SimulationContent | undefined) =>
        _setSimulationContent(assocPath([simulationUUID], content));
    // Simulation Content State - Triggers
    const setTriggers = (simulationUUID: string, triggers: Record<string, Trigger>) => {
        _setSimulationContent(
            assocPath(
                [simulationUUID, 'triggers'],
                mapObjIndexed((trigger) => decodeTrigger(trigger), triggers),
            ),
        );
    };
    const setTrigger = (trigger: Trigger) => {
        _setSimulationContent(assocPath([trigger.simulationUUID, 'triggers', trigger.uuid], decodeTrigger(trigger)));
    };
    const setTriggerOrder = (simulationUUID: string, order: string[]) =>
        _setSimulationContent(assocPath([simulationUUID, 'triggerOrder'], order));
    const setTriggerPrepreparedContentLinks = (simulationUUID: string, links: Record<string, string[]>) =>
        _setSimulationContent(assocPath([simulationUUID, 'triggerPrepreparedContentLinks'], links));
    const addTrigger = (trigger: Trigger) => {
        _setSimulationContent((currentSimContent) => {
            const checkedTrigger = decodeTrigger(trigger);
            const contentWithNewTrigger = assocPath(
                [checkedTrigger.simulationUUID, 'triggers', checkedTrigger.uuid],
                checkedTrigger,
                currentSimContent,
            );
            const contentWithNewTriggerOrder = assocPath(
                [checkedTrigger.simulationUUID, 'triggerOrder'],
                [
                    ...pathOr<string[]>([], [checkedTrigger.simulationUUID, 'triggerOrder'], currentSimContent),
                    checkedTrigger.uuid,
                ],
                contentWithNewTrigger,
            );
            const contentWithNewTriggerLinkedContent = assocPath(
                [checkedTrigger.simulationUUID, 'triggerPrepreparedContentLinks', checkedTrigger.uuid],
                [],
                contentWithNewTriggerOrder,
            );

            return contentWithNewTriggerLinkedContent;
        });
    };
    const removeTrigger = (trigger: Trigger) => {
        _setSimulationContent((currentSimContent) => {
            const contentWithoutTrigger = dissocPath<Record<string, SimulationContent>>(
                [trigger.simulationUUID, 'triggers', trigger.uuid],
                currentSimContent,
            );
            const contentWithRemovedTriggerOrder = assocPath(
                [trigger.simulationUUID, 'triggerOrder'],
                reject(
                    (uuid: string) => uuid === trigger.uuid,
                    path([trigger.simulationUUID, 'triggerOrder'], currentSimContent) || [],
                ),
                contentWithoutTrigger,
            );
            const contentWithRemovedTriggerLinks = dissocPath<Record<string, SimulationContent>>(
                [trigger.simulationUUID, 'triggerPrepreparedContentLinks', trigger.uuid],
                contentWithRemovedTriggerOrder,
            );

            return contentWithRemovedTriggerLinks;
        });
    };
    // Simulation Content State - Pages
    const setPages = (simulationUUID: string, pages: Record<string, Page>) =>
        _setSimulationContent(assocPath([simulationUUID, 'pages'], pages));
    const setPage = (page: Page) => _setSimulationContent(assocPath([page.simulationUUID, 'pages', page.uuid], page));
    const setPageOrder = (simulationUUID: string, order: string[]) =>
        _setSimulationContent(assocPath([simulationUUID, 'pageOrder'], order));
    const addPage = (page: Page) => {
        _setSimulationContent((currentSimContent) => {
            const contentWithNewPage = assocPath([page.simulationUUID, 'pages', page.uuid], page, currentSimContent);
            const contentWithNewPageOrder = assocPath(
                [page.simulationUUID, 'pageOrder'],
                [...pathOr<string[]>([], [page.simulationUUID, 'pageOrder'], currentSimContent), page.uuid],
                contentWithNewPage,
            );

            return contentWithNewPageOrder;
        });
    };
    const removePage = (page: Page) => {
        _setSimulationContent((currentSimContent) => {
            const contentWithoutPage = dissocPath<Record<string, SimulationContent>>(
                [page.simulationUUID, 'pages', page.uuid],
                currentSimContent,
            );
            const contentWithNewPageOrder = assocPath(
                [page.simulationUUID, 'pageOrder'],
                reject(
                    (uuid: string) => uuid === page.uuid,
                    path([page.simulationUUID, 'pageOrder'], currentSimContent) || [],
                ),
                contentWithoutPage,
            );

            return contentWithNewPageOrder;
        });
    };
    // Simulation Content State - Preprepared Content
    const setPrepreparedContents = (
        simulationUUID: string,
        prepreparedContents: Record<string, PrepreparedContentItem>,
    ) => _setSimulationContent(assocPath([simulationUUID, 'prepreparedContents'], prepreparedContents));
    const setPrepreparedContent = (prepreparedContent: PrepreparedContentItem) => {
        _setSimulationContent(
            assocPath(
                [prepreparedContent.simulationUUID, 'prepreparedContents', prepreparedContent.uuid],
                prepreparedContent,
            ),
        );
    };
    const removePrepreparedContentItems = (simulationUUID: string, itemUUIDs: string[]) => {
        _setSimulationContent((currentSimContent) => {
            const updatedPrepreparedContentItemsMap = reduce(
                (acc, itemUUID) => dissoc(itemUUID, acc),
                currentSimContent[simulationUUID].prepreparedContents,
                itemUUIDs,
            );
            return assocPath(
                [simulationUUID, 'prepreparedContents'],
                updatedPrepreparedContentItemsMap,
                currentSimContent,
            );
        });
    };
    // Simulation Content State - Content Items
    const addContentItems = (simulationUUID: string, contentItems: ContentItem[] | Record<string, ContentItem>) => {
        const contentItemList = map(
            // don't redecode if we think it's already decoded
            (contentData) => (typeof contentData.createdAt === 'string' ? decodeContentItem(contentData) : contentData),
            Array.isArray(contentItems) ? contentItems : values(contentItems),
        );
        const teamGroupedContentItems = reduce(
            (acc, item) => assocPath([item.teamUUID, item.uuid], item, acc),
            {} as Record<string, Record<string, ContentItem>>,
            contentItemList,
        );
        const simTeams = values(teams?.[simulationUUID] || {});

        _setSimulationContent((currentSimContent) => {
            const currentSimContentItems = currentSimContent[simulationUUID].contentItems;
            const newContentItemsMap = reduce(
                (acc, team) => ({
                    ...acc,
                    [team.uuid]: { ...currentSimContentItems[team.uuid], ...teamGroupedContentItems[team.uuid] },
                }),
                currentSimContentItems,
                simTeams,
            );
            return assocPath([simulationUUID, 'contentItems'], newContentItemsMap, currentSimContent || {});
        });
    };

    // Simulation Content State - Preprepared Emails
    const setPrepreparedEmails = (simulationUUID: string, prepreparedEmails: Record<string, PrepreparedEmailItem>) =>
        _setSimulationContent(assocPath([simulationUUID, 'prepreparedEmails'], prepreparedEmails));
    const setPrepreparedEmail = (prepreparedEmail: PrepreparedEmailItem) => {
        _setSimulationContent(
            assocPath([prepreparedEmail.simulationUUID, 'prepreparedEmails', prepreparedEmail.uuid], prepreparedEmail),
        );
    };
    const removePrepreparedEmailItems = (simulationUUID: string, itemUUIDs: string[]) => {
        _setSimulationContent((currentSimContent) => {
            const updatedPrepreparedEmailsItemsMap = reduce(
                (acc, itemUUID) => dissoc(itemUUID, acc),
                currentSimContent[simulationUUID].prepreparedEmails,
                itemUUIDs,
            );
            return assocPath(
                [simulationUUID, 'prepreparedEmails'],
                updatedPrepreparedEmailsItemsMap,
                currentSimContent,
            );
        });
    };
    // Simulation Email State - Email Items
    const addEmailItems = (simulationUUID: string, emailItems: EmailItem[] | Record<string, EmailItem>) => {
        const emailItemList = map(
            // don't redecode if we think it's already decoded
            (emailData) => (typeof emailData.createdAt === 'string' ? decodeEmailItem(emailData) : emailData),
            Array.isArray(emailItems) ? emailItems : values(emailItems),
        );
        const teamGroupedEmailItems = reduce(
            (acc, item) => assocPath([item.teamUUID, item.uuid], item, acc),
            {} as Record<string, Record<string, EmailItem>>,
            emailItemList,
        );
        _setSimulationContent((currentSimEmail) =>
            assocPath(
                [simulationUUID, 'emailItems'],
                mergeDeepRight(currentSimEmail[simulationUUID].emailItems, teamGroupedEmailItems),
                currentSimEmail,
            ),
        );
    };

    // Simulation Content State - Moments
    const addMoments = (simulationUUID: string, moments: Moment[] | Record<string, Moment>) => {
        const momentList = map(
            (contentData) => decodeMoment(contentData),
            Array.isArray(moments) ? moments : values(moments),
        );
        const teamGroupedMoments = reduce(
            (acc, moment) => assocPath([moment.contentItem.teamUUID, moment.uuid], moment, acc),
            {} as Record<string, Record<string, Moment>>,
            momentList,
        );
        _setSimulationContent((currentSimContent) =>
            assocPath(
                [simulationUUID, 'moments'],
                mergeDeepRight(currentSimContent[simulationUUID].moments, teamGroupedMoments),
                currentSimContent,
            ),
        );
    };

    const { data: ListSimulationsAndTeamsData } = listSimulationsAndTeams.hook({});
    useEffect(() => {
        if (ListSimulationsAndTeamsData?.listSimulationsAndTeams) {
            const simulationsMap = reduce<Simulation, Record<string, Simulation>>(
                (acc, simulation) => {
                    const simulationInRightShape = decodeSimulation(simulation);
                    return { ...acc, [simulationInRightShape.uuid]: simulationInRightShape };
                },
                {},
                ListSimulationsAndTeamsData?.listSimulationsAndTeams.simulations,
            );
            setSimulations(simulationsMap);
            const teamsMap = reduce<
                Omit<Team, 'pageStats'> & { pageStats: string },
                Record<string, Record<string, Team>>
            >(
                (acc, team) => {
                    const simUUID = team.simulationUUID;
                    return assocPath([simUUID, team.uuid], { ...team, pageStats: JSON.parse(team.pageStats) }, acc);
                },
                mapObjIndexed(() => ({}), simulationsMap),
                ListSimulationsAndTeamsData?.listSimulationsAndTeams.teams,
            );
            setTeams(teamsMap);
        } else {
            setSimulations(undefined);
            setTeams(undefined);
        }
    }, [ListSimulationsAndTeamsData]);

    const { data: ListStaffUsersData } = listStaffUsers.hook({});
    useEffect(() => {
        if (ListStaffUsersData?.listStaffUsers) {
            const staffUsersMap = reduce<StaffUser, Record<string, StaffUser>>(
                (acc, staffUser) => {
                    const maybeStaffUser = StaffUser.decode(staffUser);
                    if (isRight(maybeStaffUser)) {
                        const staffUUID = maybeStaffUser.right.uuid;
                        return { ...acc, [staffUUID]: maybeStaffUser.right };
                    }
                    throw new Error('Staff user was not the right shape');
                },
                {},
                ListStaffUsersData?.listStaffUsers,
            );
            setStaffUsers(staffUsersMap);
        }
    }, [ListStaffUsersData]);

    if (!simulations || !teams || !staffUsers) {
        return <FullPageSplashLoadingScreen />;
    }

    const clearSimulationData = (simulationUUID: string) => {
        setParticipants(simulationUUID, undefined);
        setSimulationContent(simulationUUID, undefined);
        setTeamAssignments(simulationUUID, undefined);
    };

    return (
        <AdminStateContext.Provider
            value={{
                simulations,
                addSimulation,
                setSimulation,
                removeSimulation,
                teams,
                setTeams,
                setTeam,
                removeTeam,
                staffUsers,
                setStaffUsers,
                participants,
                setParticipants,
                teamAssignments,
                setTeamAssignments,
                simulationContent,
                setSimulationContent,
                setTriggers,
                setTrigger,
                addTrigger,
                removeTrigger,
                setTriggerOrder,
                setTriggerPrepreparedContentLinks,
                setPages,
                setPage,
                addPage,
                removePage,
                setPageOrder,
                setPrepreparedContents,
                setPrepreparedContent,
                removePrepreparedContentItems,
                addContentItems,
                setPrepreparedEmails,
                setPrepreparedEmail,
                removePrepreparedEmailItems,
                addEmailItems,
                addMoments,
                clearSimulationData,
            }}
        >
            {children}
        </AdminStateContext.Provider>
    );
};
