import {
    ApolloQueryResult,
    FetchPolicy,
    LazyQueryHookOptions,
    MaybeMasked,
    OperationVariables,
    QueryHookOptions,
    QueryOptions,
    QueryResult,
    QueryTuple,
    useLazyQuery,
    useQuery,
} from '@apollo/client';
import { DocumentNode } from 'graphql';
import { Client } from './client';

type WrappedQueryOptions<P> = Omit<QueryOptions<P>, 'query'>;

const globalDefaultOptions = {
    fetchPolicy: 'network-only' as FetchPolicy,
};

export interface WrappedQuery<P extends OperationVariables, D> {
    promise: (
        client: Client,
        params: P,
        options?: WrappedQueryOptions<P>,
    ) => Promise<ApolloQueryResult<MaybeMasked<D>>>;
    hook: (params: P, options?: QueryHookOptions<D, P>) => QueryResult<D, P>;
    lazyHook: (params: P, options?: LazyQueryHookOptions<D, P>) => QueryTuple<D, P>;
}

const wrapQuery = <P extends OperationVariables, D>(query: DocumentNode): WrappedQuery<P, D> => {
    return {
        promise: async (
            client: Client,
            params: P,
            options: WrappedQueryOptions<P> = {},
        ): Promise<ApolloQueryResult<MaybeMasked<D>>> => {
            return client.query({
                ...globalDefaultOptions,
                ...options,
                query: query,
                variables: params,
            });
        },
        hook: (params: P, options: QueryHookOptions<D, P> = {}): QueryResult<D, P> => {
            return useQuery(query, {
                ...globalDefaultOptions,
                ...options,
                variables: params,
            });
        },
        lazyHook: (params: P, options: LazyQueryHookOptions<D, P> = {}): QueryTuple<D, P> => {
            return useLazyQuery(query, {
                ...globalDefaultOptions,
                ...options,
                variables: params,
            });
        },
    };
};

export default wrapQuery;
