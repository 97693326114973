import { Trigger } from 'polpeo-go-common/types/Trigger';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { releaseTrigger } from '../../graphql/triggers';
import { primaryColour } from '../../themes/colours';
import { PrimaryButton, SecondaryButton } from '../bits/Buttons';
import { Display } from '../bits/Display';
import { Body1, H2Heading } from '../bits/Headers';
import { Icon } from '../bits/Icon';
import { Spinner } from '../bits/Spinner';
import { Modal } from '../patterns/Modal';

const NextTriggerButton = styled.button.attrs(({ type, onClick }) => ({
    type: type || 'button',
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (e.target === e.currentTarget && (!type || type === 'button')) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (onClick) {
            onClick(e);
        }
    },
}))`
    background: #c3dc93;
    width: 42px;
    height: 42px;

    padding: 4px;

    border-radius: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    :hover {
        background: ${primaryColour};
    }
`;

interface ReleaseTriggerHeroButtonProps {
    trigger: Trigger;
    onTriggerReleased?: (trigger: Trigger) => void;
}
export const ReleaseTriggerHeroButton: FC<ReleaseTriggerHeroButtonProps> = ({ trigger, onTriggerReleased }) => {
    const [showModal, setShowModal] = useState(false);

    const [releaseTriggerMutation, { data: ReleaseTriggerData, loading: ReleaseTriggerLoading }] =
        releaseTrigger.hook();
    useEffect(() => {
        if (ReleaseTriggerData?.releaseTrigger) {
            const updatedTrigger = ReleaseTriggerData.releaseTrigger.trigger;
            setShowModal(false);
            if (onTriggerReleased) {
                onTriggerReleased({
                    ...updatedTrigger,
                    pageDressingChanges: JSON.parse(updatedTrigger.pageDressingChanges),
                });
            }
        }
    }, [ReleaseTriggerData]);

    return (
        <>
            <NextTriggerButton onClick={() => setShowModal(true)}>
                <Icon icon="nextTrigger" size={30} fill={'#ffffff'} />
            </NextTriggerButton>
            {showModal && (
                <Modal
                    cardWidth={400}
                    onModalClose={() => {
                        if (!ReleaseTriggerLoading) {
                            setShowModal(false);
                        }
                    }}
                >
                    {ReleaseTriggerLoading && (
                        <Display.HorizontalCenterVerticalCenter>
                            <Spinner />
                        </Display.HorizontalCenterVerticalCenter>
                    )}
                    {!ReleaseTriggerLoading && (
                        <Display.VerticalWithSpacing>
                            <Display.HorizontalWithSpacing>
                                <Icon icon="nextTrigger" size={52} />
                                <Display.VerticalWithSpacing gap={8}>
                                    <H2Heading as="span">Would you like to release the next trigger?</H2Heading>
                                    <Body1>{trigger.title}</Body1>
                                </Display.VerticalWithSpacing>
                            </Display.HorizontalWithSpacing>
                            <Display.HorizontalWithSpacing horizontalAlign="end">
                                <SecondaryButton onClick={() => setShowModal(false)}>Cancel</SecondaryButton>
                                <PrimaryButton
                                    onClick={() =>
                                        releaseTriggerMutation({
                                            variables: {
                                                trigger: {
                                                    ...trigger,
                                                    pageDressingChanges: JSON.stringify(trigger.pageDressingChanges),
                                                },
                                            },
                                        })
                                    }
                                >
                                    Release
                                </PrimaryButton>
                            </Display.HorizontalWithSpacing>
                        </Display.VerticalWithSpacing>
                    )}
                </Modal>
            )}
        </>
    );
};
