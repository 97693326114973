import gql from 'graphql-tag';
import { GraphQlNewPrepreparedContentItem, GraphQlPrepreparedContentItem } from 'polpeo-go-common/types/ContentItem';
import wrapMutation from '../wrapMutation';

interface CreatePrepreparedContentParams {
    prepreparedContent: GraphQlNewPrepreparedContentItem;
}

interface CreatePrepreparedContentData {
    createPrepreparedContent: GraphQlPrepreparedContentItem;
}

const mutation = gql`
    mutation createPrepreparedContent($prepreparedContent: NewPrepreparedContentInput!) {
        createPrepreparedContent(prepreparedContent: $prepreparedContent) {
            type
            uuid
            simulationUUID
            parentUUID
            persona
            content {
                pageUUID
                interactions {
                    positive
                    negative
                    shares
                }
                data
            }
            released {
                at
                items
            }
            teamUUIDs
        }
    }
`;

export const createPrepreparedContent = wrapMutation<CreatePrepreparedContentParams, CreatePrepreparedContentData>(
    mutation,
);
