import gql from 'graphql-tag';
import wrapQuery from '../wrapQuery';
import { Team } from 'polpeo-go-common/types';

interface ListTeamsBySimulationCodeParams {
    simulationCode: string;
}

interface ListTeamsBySimulationCodeData {
    listTeamsBySimulationCode: Team[];
}

const query = gql`
    query listTeamsBySimulationCode($simulationCode: String!) {
        listTeamsBySimulationCode(simulationCode: $simulationCode) {
            uuid
            name
        }
    }
`;

export const listTeamsBySimulationCode = wrapQuery<ListTeamsBySimulationCodeParams, ListTeamsBySimulationCodeData>(
    query,
);
