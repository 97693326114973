import gql from 'graphql-tag';
import { InitialScratchPadDocument } from 'polpeo-go-common/types/InitialScratchPadDocument';
import wrapMutation from '../wrapMutation';

interface EditInitialScratchPadDocumentParams {
    scratchPadDocument: InitialScratchPadDocument;
}

interface EditInitialScratchPadDocumentData {
    editInitialScratchPadDocument: InitialScratchPadDocument;
}

const mutation = gql`
    mutation editInitialScratchPadDocument($scratchPadDocument: InitialScratchPadDocumentInput!) {
        editInitialScratchPadDocument(scratchPadDocument: $scratchPadDocument) {
            uuid
            category
            path
            createdAt
            createdBy
            lastEditedBy
            notes
            name
            simulationUUID
            currentEditor
        }
    }
`;

export const editInitialScratchPadDocument = wrapMutation<
    EditInitialScratchPadDocumentParams,
    EditInitialScratchPadDocumentData
>(mutation);
