import * as t from 'io-ts';
import { date } from 'io-ts-types/lib/date';
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString';
import { contains, last, omit, pick, pipe, replace, split } from 'ramda';
import optionalToUndefined from '../t/optionalToUndefined';

export const ParticipantUser = t.type({
    id: t.string,
    fullName: t.string,
    email: t.string,
    marketingConsent: t.boolean,
    createdAt: t.union([DateFromISOString, date]),
    preferredTeam: optionalToUndefined(t.string),
});
export type ParticipantUser = t.TypeOf<typeof ParticipantUser>;
// for testing purposes
export const TEST_PARTICIPANT_USER: ParticipantUser = {
    id: 'participantId',
    fullName: 'fullName',
    email: 'email',
    marketingConsent: false,
    createdAt: new Date(),
    preferredTeam: undefined,
};

export const NewParticipantUser = t.type(
    pick(['fullName', 'email', 'marketingConsent', 'preferredTeam'], ParticipantUser.props),
);
export type NewParticipantUser = t.TypeOf<typeof NewParticipantUser>;
// for testing purposes
export const TEST_NEW_PARTICIPANT: NewParticipantUser = {
    fullName: 'fullName',
    email: 'email',
    marketingConsent: false,
    preferredTeam: undefined,
};

export const DynamoDbParticipantUserValue = t.type(omit(['id'], ParticipantUser.props));
export type DynamoDbParticipantUserValue = t.TypeOf<typeof DynamoDbParticipantUserValue>;

export const getParticipantUUIDFromSk = (sk: string): string | unknown => {
    const cleanedSk = pipe(replace('participant[', ''), replace(']', ''), replace(']', ''))(sk);
    if (contains('_', sk)) {
        return pipe(split('_'), last)(cleanedSk);
    }
    return cleanedSk;
};
