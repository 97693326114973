import gql from 'graphql-tag';
import { GetSignedPutUrlQueryData, GetSignedPutUrlQueryParams } from '../utils/GetSignedUrlQueryParams';
import wrapQuery from '../wrapQuery';

type GetSignedPutUrlForPageDressingUploadParams = GetSignedPutUrlQueryParams;

interface GetSignedPutUrlForPageDressingUploadData {
    getSignedPutUrlForPageDressingUpload: GetSignedPutUrlQueryData;
}

const query = gql`
    query getSignedPutUrlForPageDressingUpload($simulation: SimulationInput!, $file: FileDetailsInput!) {
        getSignedPutUrlForPageDressingUpload(simulation: $simulation, file: $file) {
            signedPutUrl
            path
        }
    }
`;

export const getSignedPutUrlForPageDressingUpload = wrapQuery<
    GetSignedPutUrlForPageDressingUploadParams,
    GetSignedPutUrlForPageDressingUploadData
>(query);
