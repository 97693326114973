import {
    FetchResult,
    MaybeMasked,
    MutationHookOptions,
    MutationOptions,
    MutationTuple,
    useMutation,
    OperationVariables,
} from '@apollo/client';
import { DocumentNode } from 'graphql';
import { Client } from './client';

type WrappedMutationOptions<D, P> = Omit<MutationOptions<D, P>, 'mutation'>;

interface WrappedMutation<P, D> {
    promise: (client: Client, params: P) => Promise<FetchResult<MaybeMasked<D>>>;
    hook: (options?: MutationHookOptions<D, P> | undefined) => MutationTuple<D, P>;
}

const wrapMutation = <P extends OperationVariables, D>(mutation: DocumentNode): WrappedMutation<P, D> => {
    return {
        promise: async (
            client: Client,
            params: P,
            options: WrappedMutationOptions<D, P> = {},
        ): Promise<FetchResult<MaybeMasked<D>>> => {
            return client.mutate<D, P>({
                fetchPolicy: 'no-cache',
                ...options,
                mutation: mutation,
                variables: params,
            });
        },
        hook: (options: MutationHookOptions<D, P> = {}): MutationTuple<D, P> => {
            return useMutation(mutation, {
                fetchPolicy: 'no-cache',
                ...options,
            });
        },
    };
};

export default wrapMutation;
