import gql from 'graphql-tag';
import { GetSignedPutUrlQueryData, GetSignedPutUrlQueryParams } from '../utils/GetSignedUrlQueryParams';
import wrapQuery from '../wrapQuery';

type GetSignedPutUrlForTeamOverviewUploadParams = GetSignedPutUrlQueryParams;

interface GetSignedPutUrlForTeamOverviewUploadData {
    getSignedPutUrlForTeamOverviewUpload: GetSignedPutUrlQueryData;
}

const query = gql`
    query getSignedPutUrlForTeamOverviewUpload($simulation: SimulationInput!, $file: FileDetailsInput!) {
        getSignedPutUrlForTeamOverviewUpload(simulation: $simulation, file: $file) {
            signedPutUrl
            path
        }
    }
`;

export const getSignedPutUrlForTeamOverviewUpload = wrapQuery<
    GetSignedPutUrlForTeamOverviewUploadParams,
    GetSignedPutUrlForTeamOverviewUploadData
>(query);
