import gql from 'graphql-tag';
import {
    InitialScratchPadDocument,
    NewInitialScratchpadDocument,
} from 'polpeo-go-common/types/InitialScratchPadDocument';
import wrapMutation from '../wrapMutation';

interface CreateInitialScratchPadDocumentParams {
    scratchPadDocuments: NewInitialScratchpadDocument[];
}

interface CreateInitialScratchPadDocumentData {
    createInitialScratchPadDocument: InitialScratchPadDocument[];
}

const mutation = gql`
    mutation createInitialScratchPadDocument($scratchPadDocuments: [NewInitialScratchPadDocumentInput!]!) {
        createInitialScratchPadDocument(scratchPadDocuments: $scratchPadDocuments) {
            uuid
            category
            path
            createdAt
            createdBy
            lastEditedBy
            notes
            name
            simulationUUID
            currentEditor
        }
    }
`;

export const createInitialScratchPadDocument = wrapMutation<
    CreateInitialScratchPadDocumentParams,
    CreateInitialScratchPadDocumentData
>(mutation);
