import gql from 'graphql-tag';
import { ParticipantUser } from 'polpeo-go-common/types/ParticipantUser';
import wrapSubscription from '../wrapSubscription';

interface OnEditParticipantDetailsData {
    onEditParticipantDetails: ParticipantUser;
}

const subscription = gql`
    subscription onEditParticipantDetails {
        onEditParticipantDetails {
            fullName
            email
            id
            marketingConsent
            createdAt
        }
    }
`;

export const onEditParticipantDetails = wrapSubscription<Record<string, unknown>, OnEditParticipantDetailsData>(
    subscription,
);
