import gql from 'graphql-tag';
import { GraphQlPrepreparedContentItem } from 'polpeo-go-common/types/ContentItem';
import wrapMutation from '../wrapMutation';

interface DeletePrepreparedContentParams {
    prepreparedContent: GraphQlPrepreparedContentItem;
}

interface DeletePrepreparedContentData {
    deletePrepreparedContent: string[];
}

const mutation = gql`
    mutation deletePrepreparedContent($prepreparedContent: PrepreparedContentInput!) {
        deletePrepreparedContent(prepreparedContent: $prepreparedContent)
    }
`;

export const deletePrepreparedContent = wrapMutation<DeletePrepreparedContentParams, DeletePrepreparedContentData>(
    mutation,
);
