import gql from 'graphql-tag';
import { GetSignedPutUrlQueryData } from '../utils/GetSignedUrlQueryParams';
import wrapQuery from '../wrapQuery';
import { FileDetails } from 'polpeo-go-common/types/FileDetails';
import { BasicSimulation } from 'polpeo-go-common/types/Simulation';
interface GetSignedPutUrlforScratchPadDocumentUploadParams {
    simulation: BasicSimulation;
    file: FileDetails;
    teamUUID: string;
}

interface GetSignedPutUrlforScratchPadDocumentUploadData {
    getSignedPutUrlforScratchPadDocumentUpload: GetSignedPutUrlQueryData;
}

const query = gql`
    query getSignedPutUrlforScratchPadDocumentUpload(
        $teamUUID: String!
        $simulation: BasicSimulationInput!
        $file: FileDetailsInput!
    ) {
        getSignedPutUrlforScratchPadDocumentUpload(teamUUID: $teamUUID, simulation: $simulation, file: $file) {
            signedPutUrl
            path
        }
    }
`;

export const getSignedPutUrlforScratchPadDocumentUpload = wrapQuery<
    GetSignedPutUrlforScratchPadDocumentUploadParams,
    GetSignedPutUrlforScratchPadDocumentUploadData
>(query);
