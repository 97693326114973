import gql from 'graphql-tag';
import { FileDetails } from 'polpeo-go-common/types/FileDetails';
import { Simulation } from 'polpeo-go-common/types/Simulation';
import { GetSignedPutUrlQueryData } from '../utils/GetSignedUrlQueryParams';
import wrapQuery from '../wrapQuery';
interface GetSignedPutUrlforScratchPadDocumentUploadParams {
    simulation: Simulation;
    file: FileDetails;
}

interface GetSignedPutUrlforScratchPadDocumentUploadData {
    getSignedPutUrlforInitialScratchPadDocumentUpload: GetSignedPutUrlQueryData;
}

const query = gql`
    query getSignedPutUrlforInitialScratchPadDocumentUpload($simulation: SimulationInput!, $file: FileDetailsInput!) {
        getSignedPutUrlforInitialScratchPadDocumentUpload(simulation: $simulation, file: $file) {
            signedPutUrl
            path
        }
    }
`;

export const getSignedPutUrlforInitialScratchPadDocumentUpload = wrapQuery<
    GetSignedPutUrlforScratchPadDocumentUploadParams,
    GetSignedPutUrlforScratchPadDocumentUploadData
>(query);
