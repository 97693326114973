import gql from 'graphql-tag';
import { Simulation } from 'polpeo-go-common/types/Simulation';
import { Team } from 'polpeo-go-common/types/Team';
import wrapQuery from '../wrapQuery';

interface ListSimulationsAndTeamsData {
    listSimulationsAndTeams: {
        simulations: Simulation[];
        teams: (Omit<Team, 'pageStats'> & { pageStats: string /* AWSJSON - Record<string, Record<string>> */ })[];
    };
}

const query = gql`
    query listSimulationsAndTeams {
        listSimulationsAndTeams {
            simulations {
                uuid
                name
                code
                createdAt
                createdBy
                scheduledFor
                lobbyOpenedAt
                startedAt
                completedAt
                archivedAt
                clientName
                isReady
                emailDomainWhitelist
                expectedNumberParticipants
                administratorUUIDs
                briefingDocuments {
                    name
                    createdAt
                    path
                    createdBy
                }
                briefingNotes {
                    lastEditedAt
                    lastEditedBy
                    notes
                }
                startingOverview {
                    title
                    description
                    headerImage
                }
                scratchPadDocuments {
                    uuid
                    category
                    path
                    createdAt
                    createdBy
                    lastEditedBy
                    notes
                    name
                    simulationUUID
                }
                showParticipantsMomentsGraph
                createMomentsPermission
            }
            teams {
                uuid
                simulationUUID
                name
                roleplayerUUIDs
                documentUUIDs
                overview {
                    title
                    description
                    headerImage
                }
                pageStats
            }
        }
    }
`;

export const listSimulationsAndTeams = wrapQuery<Record<string, unknown>, ListSimulationsAndTeamsData>(query);
