import gql from 'graphql-tag';
import { BasicSimulation } from 'polpeo-go-common/types/Simulation';
import wrapQuery from '../wrapQuery';

interface GetOpenSimulationFromCodeParams {
    simulationCode: string;
}

interface GetOpenSimulationFromCodeData {
    getOpenSimulationFromCode: BasicSimulation;
}

const query = gql`
    query getOpenSimulationFromCode($simulationCode: String!) {
        getOpenSimulationFromCode(simulationCode: $simulationCode) {
            uuid
            name
            code
            startedAt
            completedAt
            clientName
            showParticipantsMomentsGraph
        }
    }
`;

export const getOpenSimulationFromCode = wrapQuery<GetOpenSimulationFromCodeParams, GetOpenSimulationFromCodeData>(
    query,
);
