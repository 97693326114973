import gql from 'graphql-tag';
import { ParticipantUser } from 'polpeo-go-common/types/ParticipantUser';
import wrapQuery from '../wrapQuery';

interface GetParticipantsForSimulationParams {
    simulationUUID: string;
}

interface GetParticipantsForSimulationData {
    getParticipantsForSimulation: ParticipantUser[];
}

const query = gql`
    query getParticipantsForSimulation($simulationUUID: String!) {
        getParticipantsForSimulation(simulationUUID: $simulationUUID) {
            id
            fullName
            email
            marketingConsent
            createdAt
            preferredTeam
        }
    }
`;

export const getParticipantsForSimulation = wrapQuery<
    GetParticipantsForSimulationParams,
    GetParticipantsForSimulationData
>(query);
