import gql from 'graphql-tag';
import { GraphQlPrepreparedEmailItem } from 'polpeo-go-common/types/EmailItem';
import wrapMutation from '../wrapMutation';

interface ReleasePrepreparedEmailParams {
    prepreparedItem: GraphQlPrepreparedEmailItem;
}

export type PrepreparedEmailReleasedMeta = Pick<GraphQlPrepreparedEmailItem, 'type' | 'uuid' | 'released'>;
interface ReleasePrepreparedEmailData {
    releasePrepreparedEmail: {
        simulationUUID: string;
        item: GraphQlPrepreparedEmailItem;
        items: Array<PrepreparedEmailReleasedMeta>;
    };
}

const mutation = gql`
    mutation releasePrepreparedEmail($prepreparedItem: PrepreparedEmailInput!) {
        releasePrepreparedEmail(prepreparedItem: $prepreparedItem) {
            simulationUUID
            item {
                __typename
                ... on PrepreparedEmail {
                    type
                    uuid
                    simulationUUID
                    parentUUID
                    persona
                    content {
                        recipients
                        subject
                        message
                    }
                    released {
                        at
                        items
                    }
                }
            }
            items {
                __typename
                ... on PrepreparedEmail {
                    type
                    uuid
                    released {
                        at
                        items
                    }
                }
            }
        }
    }
`;

export const releasePrepreparedEmail = wrapMutation<ReleasePrepreparedEmailParams, ReleasePrepreparedEmailData>(
    mutation,
);
