import gql from 'graphql-tag';
import { ScratchPadDocument } from 'polpeo-go-common/types/ScratchPadDocument';
import wrapSubscription from '../wrapSubscription';

interface OnDeleteScratchPadDocumentParams {
    simulationUUID: string;
}

interface OnDeleteScratchPadDocumentData {
    onDeleteScratchPadDocument: ScratchPadDocument;
}

const subscription = gql`
    subscription onDeleteScratchPadDocument($simulationUUID: String!) {
        onDeleteScratchPadDocument(simulationUUID: $simulationUUID) {
            uuid
            simulationUUID
            teamUUID
            category
            path
            createdAt
            createdBy
            lastEditedBy
            notes
            name
        }
    }
`;

export const onDeleteScratchPadDocument = wrapSubscription<
    OnDeleteScratchPadDocumentParams,
    OnDeleteScratchPadDocumentData
>(subscription);
