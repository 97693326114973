import styled from 'styled-components/macro';
import {
    destructiveColour,
    destructiveDarkColour,
    grey3Colour,
    grey6Colour,
    primaryColour,
    primaryDarkColour,
    secondaryButtonAlternateColour,
} from '../../../themes/colours';

interface ButtonProps {
    small?: boolean;
}
export const Button = styled.button.attrs(({ type, onClick }) => ({
    type: type || 'button',
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (e.target === e.currentTarget && (!type || type === 'button')) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (onClick) {
            onClick(e);
        }
    },
}))<ButtonProps>`
    background: none;
    padding: ${({ small }) => (small ? '5px' : '10px 16px')};

    border: 2px solid;
    border-radius: 5px;

    display: flex;
    align-items: center;
    > * {
        flex: 0 0 auto;
    }

    &:focus {
        outline: none;
    }

    &[disabled] {
        border-color: ${grey3Colour};
        color: ${grey3Colour};
    }

    font-size: 14px;
`;

export const PrimaryButton = styled(Button)`
    background: ${primaryColour};
    color: #fff;
    border-color: ${primaryColour};

    &:hover:not([disabled]),
    &:focus:not([disabled]) {
        background: ${primaryDarkColour};
        border-color: ${primaryDarkColour};
    }

    &[disabled] {
        background: ${grey3Colour};
        color: #fff;
    }
`;

export const DestructiveButton = styled(PrimaryButton)`
    background: ${destructiveColour};
    color: #fff;
    border-color: ${destructiveColour};

    &:hover:not([disabled]),
    &:focus:not([disabled]) {
        background: ${destructiveDarkColour};
        border-color: ${destructiveDarkColour};
    }
`;

export const SecondaryButton = styled(Button)`
    background: #fff;
    color: ${primaryColour};
    border-color: currentColor;

    &:hover:not([disabled]),
    &:focus:not([disabled]) {
        background: ${secondaryButtonAlternateColour};
    }
`;

export const DestructiveSecondaryButton = styled(SecondaryButton)`
    color: ${destructiveColour};
    border-color: ${destructiveColour};

    &:hover:not([disabled]),
    &:focus:not([disabled]) {
        background: #ffffff;
    }
`;

export const TertiaryButton = styled(SecondaryButton)`
    border-color: #ffffff;

    &:disabled,
    &[disabled] {
        border-color: #ffffff;
        color: ${grey3Colour};
    }
`;

export const TextButton = styled(TertiaryButton)`
    display: inline-block;
    padding: 0;
    border-color: transparent;
    background: none;

    &:hover:not([disabled]),
    &:focus:not([disabled]) {
        background: none;
        color: ${grey6Colour};
    }

    &:disabled,
    &[disabled] {
        border-color: transparent;
    }
`;
