import { useApolloClient } from '@apollo/client';
import { Simulation } from 'polpeo-go-common/types/Simulation';
import { StaffUser } from 'polpeo-go-common/types/StaffUser';
import { difference, groupBy, join, keys, map, toPairs, values } from 'ramda';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';
import { PrimaryButton } from '../../../bits/Buttons';
import { Display } from '../../../bits/Display';
import { Spinner } from '../../../bits/Spinner';
import { useUnsavedWorkPrompt } from '../../../hooks/useUnsavedWorkPrompt';
import { Card } from '../../../patterns/Card';
import { DropZone } from '../../../patterns/DropZone';
import { AdminStateContext } from '../../../WithAdminState/adminState';
import { FormGrid } from '../../FormGrid';
import { pageTemplates } from '../../PageTemplate/templates';
import { createSimulationAndAssociatedData, parseXlsx, UploadedSimulationAndAssociatedData } from './utils';

const Container = styled(Display.VerticalWithSpacing).attrs(() => ({
    horizontalAlign: 'start',
}))`
    margin: 20px;
`;

export enum SimulationUploadStep {
    NO_FILE = 'NO_FILE',
    PARSED_FILE = 'PARSED_FILE',
    CREATING_SIMULATION = 'CREATING_SIMULATION',
    FINISHED = 'FINISHED',
}

export const SimulationUploadPage: FC = () => {
    const history = useHistory();
    const client = useApolloClient();
    const adminState = useContext(AdminStateContext);

    const [simulationFile, setSimulationFile] = useState<File>();
    const [imageFiles, setImageFiles] = useState<Record<string, File>>({});
    const [newSimAndData, setNewSimAndData] = useState<UploadedSimulationAndAssociatedData>();
    const {
        simulation: parsedSimulation,
        teams: parsedTeams,
        triggers: parsedTriggers,
        pages: parsedPages,
        prepreparedContent: parsedPrepreparedContent,
        prepreparedEmails: parsedPrepreparedEmails,
    } = newSimAndData || {};
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [uploadStep, setUploadStep] = useState(SimulationUploadStep.NO_FILE);
    const [newSimulation, setNewSimulation] = useState<Simulation>();

    const groupedParsedPrepreparedContent = useMemo(
        () => groupBy((item) => item.content.pageTitle, parsedPrepreparedContent?.data || []),
        [parsedPrepreparedContent],
    );
    const groupedParsedPrepreparedEmail = useMemo(
        () => groupBy((item) => item.triggerTitle || 'Unassigned', parsedPrepreparedEmails || []),
        [parsedPrepreparedEmails],
    );

    useEffect(() => {
        const parseFile = async () => {
            if (simulationFile) {
                try {
                    const parsedResults = await parseXlsx(
                        simulationFile,
                        values(adminState.staffUsers as Record<string, StaffUser>),
                    );
                    setNewSimAndData(parsedResults);
                    setImageFiles({});
                    setErrorMessages([]);
                    setUploadStep(SimulationUploadStep.PARSED_FILE);
                } catch (errors) {
                    setNewSimAndData(undefined);
                    setImageFiles({});
                    setErrorMessages(errors as string[]);
                }
            }
        };
        parseFile();
    }, [simulationFile]);

    useEffect(() => {
        if (uploadStep === SimulationUploadStep.FINISHED && newSimulation) {
            history.push(`/admin/simulation/${newSimulation.uuid}/manage`);
        }
    }, [uploadStep, newSimulation]);

    const isStartingTeamOverviewImageUploaded =
        !!parsedSimulation &&
        (!parsedSimulation.startingOverview?.headerImage ||
            (parsedSimulation.startingOverview?.headerImage &&
                imageFiles[parsedSimulation.startingOverview?.headerImage]));
    const isAllImagesUploaded =
        !!parsedPrepreparedContent && !difference(parsedPrepreparedContent.images, keys(imageFiles)).length;
    const isAllDressingsUploaded = !!parsedPages && !difference(parsedPages.images, keys(imageFiles)).length;
    const isAllSimulationImagesUploaded =
        isStartingTeamOverviewImageUploaded && isAllImagesUploaded && isAllDressingsUploaded;

    const { unsavedPromptComponent: UnsavedPrompt } = useUnsavedWorkPrompt(
        !!simulationFile && uploadStep !== SimulationUploadStep.FINISHED,
    );

    return (
        <Container>
            <UnsavedPrompt />
            <DropZone
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onAcceptedFiles={(files: File[]) => {
                    if (files.length === 1) {
                        setSimulationFile(files[0]);
                    }
                }}
            >
                {simulationFile && <p>Current file: {simulationFile.name}</p>}
                {!simulationFile && <p>Drag and drop your simulation file here or click to select a file</p>}
            </DropZone>
            {parsedSimulation && (
                <Card header="Simulation">
                    <FormGrid
                        fields={[
                            ['Simulation Name', <>{parsedSimulation.name}</>],
                            ['Client Name', <>{parsedSimulation.clientName}</>],
                            ['Whitelist', <>{join(', ', parsedSimulation.emailDomainWhitelist)}</>],
                            ['Expected Participants', <>{parsedSimulation.expectedNumberParticipants}</>],
                            ['Scheduled for', <>{parsedSimulation.scheduledFor?.toISOString()}</>],
                            ['Administrators', <>{join(', ', parsedSimulation.administratorEmails)}</>],
                            [
                                'Show Highlights Graph to Participants',
                                <>{parsedSimulation.showParticipantsMomentsGraph ? 'yes' : 'no'}</>,
                            ],
                            [
                                'Allow Staff to Create Highlights',
                                <>{parsedSimulation.createMomentsPermission.length ? 'yes' : 'no'}</>,
                            ],
                            [
                                'Starting Overview Image',
                                <>
                                    {parsedSimulation.startingOverview?.headerImage ? (
                                        <DropZone
                                            accept="image/*"
                                            onAcceptedFiles={(files: File[]) => {
                                                if (
                                                    files.length === 1 &&
                                                    parsedSimulation.startingOverview?.headerImage
                                                ) {
                                                    setImageFiles({
                                                        ...imageFiles,
                                                        [parsedSimulation.startingOverview?.headerImage]: files[0],
                                                    });
                                                }
                                            }}
                                        >
                                            {imageFiles[parsedSimulation.startingOverview.headerImage] && (
                                                <p>
                                                    Current image:{' '}
                                                    {imageFiles[parsedSimulation.startingOverview.headerImage].name}
                                                </p>
                                            )}
                                            {!imageFiles[parsedSimulation.startingOverview.headerImage] && (
                                                <p>Drag and drop your image here or click to select a image</p>
                                            )}
                                        </DropZone>
                                    ) : (
                                        'No image'
                                    )}
                                </>,
                            ],
                            ['Starting Overview Title', <>{parsedSimulation.startingOverview?.title}</>],
                            ['Starting Overview Description', <>{parsedSimulation.startingOverview?.description}</>],
                        ]}
                    />
                </Card>
            )}
            {parsedTeams && !!parsedTeams.length && (
                <Card header="Teams">
                    <FormGrid
                        fields={map(
                            (newTeam) => [
                                newTeam.name,
                                <>
                                    Roleplayers:{' '}
                                    {join(
                                        ', ',
                                        map((uuid) => {
                                            const staffUser = adminState.staffUsers[uuid];
                                            if (!staffUser) {
                                                return '';
                                            }
                                            return staffUser.name;
                                        }, newTeam.roleplayerUUIDs),
                                    ) || 'No assigned roleplayers'}
                                </>,
                            ],
                            parsedTeams,
                        )}
                    />
                </Card>
            )}
            {parsedTriggers && !!parsedTriggers.data.length && (
                <Card header="Triggers">
                    <FormGrid
                        fields={map(
                            (newTrigger) => [
                                newTrigger.title,
                                <>
                                    <ul>
                                        <li>Trigger Title: {newTrigger.title}</li>
                                        <li>Optional: {newTrigger.isOptional ? 'Yes' : 'No'}</li>
                                        <li>Simulated Time: {newTrigger.simulatedTime}</li>
                                    </ul>
                                </>,
                            ],
                            parsedTriggers.data,
                        )}
                    />
                </Card>
            )}
            {parsedTriggers && !!parsedTriggers.images.length && (
                <Card header="Trigger Page Dressing and Overview Images">
                    <FormGrid
                        fields={map((imageName) => {
                            return [
                                imageName,
                                <>
                                    <DropZone
                                        accept="image/*"
                                        onAcceptedFiles={(files: File[]) => {
                                            if (files.length === 1) {
                                                setImageFiles({ ...imageFiles, [imageName]: files[0] });
                                            }
                                        }}
                                    >
                                        {imageFiles[imageName] && <p>Current image: {imageFiles[imageName].name}</p>}
                                        {!imageFiles[imageName] && (
                                            <p>Drag and drop your image here or click to select a image</p>
                                        )}
                                    </DropZone>
                                </>,
                            ];
                        }, parsedTriggers.images)}
                    />
                </Card>
            )}
            {parsedPages && !!parsedPages.data.length && (
                <Card header="Pages">
                    <FormGrid
                        fields={map(
                            (newPage) => [
                                newPage.name,
                                <>
                                    <ul>
                                        <li>Page Name: {newPage.name}</li>
                                        <li>Page Template: {pageTemplates[newPage.templateUUID].name}</li>
                                    </ul>
                                </>,
                            ],
                            parsedPages.data,
                        )}
                    />
                </Card>
            )}
            {parsedPages && !!parsedPages.images.length && (
                <Card header="Page Dressing Images">
                    <FormGrid
                        fields={map((imageName) => {
                            return [
                                imageName,
                                <>
                                    <DropZone
                                        accept="image/*"
                                        onAcceptedFiles={(files: File[]) => {
                                            if (files.length === 1) {
                                                setImageFiles({ ...imageFiles, [imageName]: files[0] });
                                            }
                                        }}
                                    >
                                        {imageFiles[imageName] && <p>Current image: {imageFiles[imageName].name}</p>}
                                        {!imageFiles[imageName] && (
                                            <p>Drag and drop your image here or click to select a image</p>
                                        )}
                                    </DropZone>
                                </>,
                            ];
                        }, parsedPages.images)}
                    />
                </Card>
            )}
            {parsedPrepreparedContent && !!parsedPrepreparedContent.data.length && (
                <Card header="Preprepared Contents">
                    <FormGrid
                        fields={map(([pageName, items]) => {
                            const groupedByTrigger = groupBy((item) => item.triggerTitle || 'Unassigned', items);
                            return [
                                pageName,
                                <>
                                    <ul>
                                        {map(
                                            ([triggerName, itemsInTrigger]) => (
                                                <li>
                                                    {triggerName}: {itemsInTrigger.length} items
                                                </li>
                                            ),
                                            toPairs(groupedByTrigger),
                                        )}
                                    </ul>
                                </>,
                            ];
                        }, toPairs(groupedParsedPrepreparedContent))}
                    />
                </Card>
            )}
            {parsedPrepreparedContent && !!parsedPrepreparedContent.images.length && (
                <Card header="Preprepared Content Images">
                    <FormGrid
                        fields={map((imageName) => {
                            return [
                                imageName,
                                <>
                                    <DropZone
                                        accept="image/*"
                                        onAcceptedFiles={(files: File[]) => {
                                            if (files.length === 1) {
                                                setImageFiles({ ...imageFiles, [imageName]: files[0] });
                                            }
                                        }}
                                    >
                                        {imageFiles[imageName] && <p>Current image: {imageFiles[imageName].name}</p>}
                                        {!imageFiles[imageName] && (
                                            <p>Drag and drop your image here or click to select a image</p>
                                        )}
                                    </DropZone>
                                </>,
                            ];
                        }, parsedPrepreparedContent.images)}
                    />
                </Card>
            )}
            {parsedPrepreparedEmails && !!parsedPrepreparedEmails.length && (
                <Card header="Preprepared Emails">
                    <FormGrid
                        fields={map(([triggerTitle, items]) => {
                            return [
                                triggerTitle,
                                <>
                                    {items.length} {items.length === 1 ? 'item' : 'items'}
                                </>,
                            ];
                        }, toPairs(groupedParsedPrepreparedEmail))}
                    />
                </Card>
            )}
            {newSimAndData && (
                <PrimaryButton
                    disabled={!isAllSimulationImagesUploaded}
                    onClick={async () => {
                        setUploadStep(SimulationUploadStep.CREATING_SIMULATION);
                        const sim = await createSimulationAndAssociatedData(
                            client,
                            adminState,
                            newSimAndData,
                            imageFiles,
                        );
                        setNewSimulation(sim);
                        setUploadStep(SimulationUploadStep.FINISHED);
                    }}
                >
                    Create simulation
                </PrimaryButton>
            )}
            {uploadStep === SimulationUploadStep.CREATING_SIMULATION && <Spinner />}
            {!!errorMessages.length && (
                <ul>
                    {map(
                        (msg) => (
                            <li>{msg}</li>
                        ),
                        errorMessages,
                    )}
                </ul>
            )}
        </Container>
    );
};
