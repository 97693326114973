import gql from 'graphql-tag';
import wrapMutation from '../wrapMutation';

interface UpdateTriggerPrepreparedContentLinksParams {
    simulationUUID: string;
    newLinks: string; // AWSJSON Record<string, string[]>
}

interface UpdateTriggerPrepreparedContentLinksData {
    updateTriggerPrepreparedContentLinks: string; // AWSJSON Record<string, string[]>
}

const mutation = gql`
    mutation updateTriggerPrepreparedContentLinks($simulationUUID: String!, $newLinks: AWSJSON!) {
        updateTriggerPrepreparedContentLinks(simulationUUID: $simulationUUID, newLinks: $newLinks)
    }
`;

export const updateTriggerPrepreparedContentLinks = wrapMutation<
    UpdateTriggerPrepreparedContentLinksParams,
    UpdateTriggerPrepreparedContentLinksData
>(mutation);
