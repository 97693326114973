import gql from 'graphql-tag';
import { GetSignedPutUrlQueryData, BasicSimGetSignedPutUrlQueryParams } from '../utils/GetSignedUrlQueryParams';
import wrapQuery from '../wrapQuery';

type GetSignedPutUrlForContentUploadParams = BasicSimGetSignedPutUrlQueryParams;

interface GetSignedPutUrlForContentUploadData {
    getSignedPutUrlForContentUpload: GetSignedPutUrlQueryData;
}

const query = gql`
    query getSignedPutUrlForContentUpload($simulation: BasicSimulationInput!, $file: FileDetailsInput!) {
        getSignedPutUrlForContentUpload(simulation: $simulation, file: $file) {
            signedPutUrl
            path
        }
    }
`;

export const getSignedPutUrlForContentUpload = wrapQuery<
    GetSignedPutUrlForContentUploadParams,
    GetSignedPutUrlForContentUploadData
>(query);
