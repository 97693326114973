import gql from 'graphql-tag';
import { GraphQlPrepreparedContentItem } from 'polpeo-go-common/types/ContentItem';
import wrapMutation from '../wrapMutation';

interface ReleasePrepreparedContentParams {
    prepreparedItem: GraphQlPrepreparedContentItem;
}

export type PrepreparedContentReleasedMeta = Pick<GraphQlPrepreparedContentItem, 'type' | 'uuid' | 'released'>;
interface ReleasePrepreparedContentData {
    releasePrepreparedContent: {
        simulationUUID: string;
        item: GraphQlPrepreparedContentItem;
        items: Array<PrepreparedContentReleasedMeta>;
    };
}

const mutation = gql`
    mutation releasePrepreparedContent($prepreparedItem: PrepreparedContentInput!) {
        releasePrepreparedContent(prepreparedItem: $prepreparedItem) {
            simulationUUID
            item {
                __typename
                ... on PrepreparedContent {
                    type
                    uuid
                    simulationUUID
                    parentUUID
                    persona
                    content {
                        pageUUID
                        interactions {
                            positive
                            negative
                            shares
                        }
                        data
                    }
                    released {
                        at
                        items
                    }
                    teamUUIDs
                }
            }
            items {
                __typename
                ... on PrepreparedContent {
                    type
                    uuid
                    released {
                        at
                        items
                    }
                }
            }
        }
    }
`;

export const releasePrepreparedContent = wrapMutation<ReleasePrepreparedContentParams, ReleasePrepreparedContentData>(
    mutation,
);
