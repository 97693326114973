import gql from 'graphql-tag';
import { InitialScratchPadDocument } from 'polpeo-go-common/types/InitialScratchPadDocument';
import wrapMutation from '../wrapMutation';

interface DeleteInitialScratchPadDocumentParams {
    scratchPadDocument: InitialScratchPadDocument;
}

interface DeleteInitialScratchPadDocumentData {
    deleteInitialScratchPadDocument: InitialScratchPadDocument;
}

const mutation = gql`
    mutation deleteInitialScratchPadDocument($scratchPadDocument: InitialScratchPadDocumentInput!) {
        deleteInitialScratchPadDocument(scratchPadDocument: $scratchPadDocument) {
            uuid
            category
            path
            createdAt
            createdBy
            lastEditedBy
            notes
            name
            simulationUUID
            currentEditor
        }
    }
`;

export const deleteInitialScratchPadDocument = wrapMutation<
    DeleteInitialScratchPadDocumentParams,
    DeleteInitialScratchPadDocumentData
>(mutation);
