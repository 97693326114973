import QRCodeCanvas from 'qrcode.react';
import { toLower } from 'ramda';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { validateTOTPCode } from '../../../../utils/validateTOTPCode';
import AuthContext, { AppUser } from '../../../WithAuth/AuthContext';
import { PrimaryButton, SecondaryButton } from '../../../bits/Buttons';
import { Display } from '../../../bits/Display';
import { PasswordInput, TextInput } from '../../../bits/FormFields';
import { Body3, H2Heading, StyledErrorMessage } from '../../../bits/Headers';
import { Link } from '../../../bits/Link';
import { Spinner } from '../../../bits/Spinner';
import { SplashContainer, SplashHeroCard } from '../../../bits/SplashContainer';
import { Logo } from '../../../patterns/Logo';

interface CreateNewPasswordProps {
    staffUser: AppUser;
    setLoginStep: React.Dispatch<React.SetStateAction<LoginSteps>>;
}

const CreateNewPasswordOnFirstSignInForm: React.FC<CreateNewPasswordProps> = ({
    staffUser,
    setLoginStep,
}: CreateNewPasswordProps): React.ReactElement => {
    const { completeNewPassword } = useContext(AuthContext);
    const [error, setError] = useState('');
    const [newPassword, setNewPassword] = useState('');

    const onCreateNewPassword = async (event: React.SyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();
        setError('');
        try {
            await completeNewPassword(staffUser, newPassword);
            setLoginStep(LoginSteps.SignIn);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            setError(err.message);
        }
    };

    return (
        <form onSubmit={onCreateNewPassword}>
            <Display.VerticalWithSpacing>
                <Logo />
                <Display.VerticalWithSpacing gap={10}>
                    <H2Heading>Create your new password!</H2Heading>
                    <span>
                        Not Staff? <Link to="/">Back to simulation</Link>
                    </span>
                </Display.VerticalWithSpacing>
                <PasswordInput
                    validationFailed={!!error}
                    placeholder="New Password"
                    id="signIn-NewPassword"
                    onChange={({ target }) => setNewPassword(target.value)}
                    value={newPassword}
                />
                <Body3>Use 8 or more characters with a mix of upper and lowercase letters, numbers and symbols</Body3>
                {!!error && <StyledErrorMessage>{error}</StyledErrorMessage>}
                <Display.RightAlign>
                    <PrimaryButton type="submit" disabled={!newPassword}>
                        Confirm
                    </PrimaryButton>
                </Display.RightAlign>
            </Display.VerticalWithSpacing>
        </form>
    );
};

interface SetupMFAFormProps {
    staffUser: AppUser;
}

const ReducedMarginVerticalWithSpacing = styled(Display.VerticalWithSpacing)`
    margin-bottom: -15px;
`;

const SetupMFAForm: React.FC<SetupMFAFormProps> = ({ staffUser }: SetupMFAFormProps): React.ReactElement => {
    const { setupTOTP, submitTOTPCode, signOutAuthenticatedUser } = useContext(AuthContext);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [userTOTPCode, setUserTOTPCode] = useState('');
    const [appTOTPCode, setAppTOTPCode] = useState('');
    const history = useHistory();

    useEffect(() => {
        (async () => {
            try {
                const code = await setupTOTP(staffUser);
                const str =
                    'otpauth://totp/AWSCognito:' + staffUser.attributes?.email + '?secret=' + code + '&issuer=Polpeo';
                setAppTOTPCode(str);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (err: any) {
                if (err.message) setError(err.message);
            }
        })();
    }, []);

    const submitUserCode = async (event: React.SyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();
        setLoading(true);
        setError('');
        if (userTOTPCode) {
            try {
                const validationMessage = validateTOTPCode(userTOTPCode);
                if (validationMessage) {
                    setError(validationMessage);
                    setLoading(false);
                } else {
                    await submitTOTPCode(staffUser, userTOTPCode);
                    history.push('/admin');
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (err: any) {
                setError(err.message);
                setLoading(false);
            }
        }
    };

    const onCancel = async (event: React.SyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();
        signOutAuthenticatedUser();
    };

    return (
        <form onSubmit={submitUserCode}>
            <Display.VerticalWithSpacing>
                <Logo />
                <Display.VerticalWithSpacing gap={10}>
                    <H2Heading>Enable Multi-Factor Authentication</H2Heading>
                    Use the authenticator app (Authy, Google Authenticator, etc...) on your device. Scan the QR code and
                    enter the code that is displayed into the form below.
                </Display.VerticalWithSpacing>
                {(!appTOTPCode || loading) && (
                    <Display.HorizontalCenterVerticalCenter>
                        <Spinner height={150} />
                    </Display.HorizontalCenterVerticalCenter>
                )}
                {appTOTPCode && !loading && (
                    <>
                        <Display.HorizontalCenterVerticalCenter>
                            <QRCodeCanvas value={appTOTPCode} />
                        </Display.HorizontalCenterVerticalCenter>
                        <ReducedMarginVerticalWithSpacing>
                            <label>Enter your 6-digit code</label>
                        </ReducedMarginVerticalWithSpacing>
                        <TextInput
                            placeholder="code"
                            value={userTOTPCode}
                            validationFailed={!!error}
                            onChange={({ target }) => setUserTOTPCode(target.value)}
                        />
                    </>
                )}
                {!!error && <StyledErrorMessage>{error}</StyledErrorMessage>}
                <Display.HorizontalWithSpacing horizontalAlign="end" verticalCenter>
                    <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
                    <PrimaryButton type="submit" disabled={loading || !userTOTPCode}>
                        Confirm
                    </PrimaryButton>
                </Display.HorizontalWithSpacing>
            </Display.VerticalWithSpacing>
        </form>
    );
};

interface SubmitMFAFormProps {
    staffUser: AppUser;
}

const SubmitMFAForm: React.FC<SubmitMFAFormProps> = ({ staffUser }: SubmitMFAFormProps): React.ReactElement => {
    const { confirmSignInWithMFA } = useContext(AuthContext);
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState<string>('');
    const history = useHistory();

    const submitUserCode = async (event: React.SyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();
        setLoading(true);
        setError('');
        try {
            if (code) {
                const validationMessage = validateTOTPCode(code);
                if (validationMessage) {
                    setError(validationMessage);
                    setLoading(false);
                } else {
                    await confirmSignInWithMFA(staffUser, code);
                    history.push('/admin');
                }
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            setError(err.message);
            setLoading(false);
        }
    };

    return (
        <form onSubmit={submitUserCode}>
            <Display.VerticalWithSpacing>
                <Logo />
                <Display.VerticalWithSpacing gap={10}>
                    <H2Heading>Multi-Factor Authentication</H2Heading>
                    Using your authenticator app (Authy, Google Authenticator, etc...) enter the code that is displayed
                    into the form below.
                </Display.VerticalWithSpacing>
                {loading ? (
                    <Display.HorizontalCenterVerticalCenter>
                        <Spinner height={150} />
                    </Display.HorizontalCenterVerticalCenter>
                ) : (
                    <>
                        <TextInput
                            value={code}
                            validationFailed={!!error}
                            onChange={({ target }) => setCode(target.value)}
                        />
                        {!!error && <StyledErrorMessage>{error}</StyledErrorMessage>}
                        <Display.HorizontalWithSpacing horizontalAlign="end" verticalCenter>
                            <SecondaryButton onClick={() => history.push('/admin')}>Cancel</SecondaryButton>
                            <PrimaryButton type="submit" disabled={loading || !code}>
                                Confirm
                            </PrimaryButton>
                        </Display.HorizontalWithSpacing>
                    </>
                )}
            </Display.VerticalWithSpacing>
        </form>
    );
};

interface SignInProps {
    setLoginStep: React.Dispatch<React.SetStateAction<LoginSteps>>;
    setStaffUser: React.Dispatch<React.SetStateAction<AppUser | undefined>>;
}

const SignInForm: React.FC<SignInProps> = ({ setLoginStep, setStaffUser }: SignInProps): React.ReactElement => {
    const { signIn } = useContext(AuthContext);
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string>('');

    const onSignIn = async (event: React.SyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();
        setLoading(true);
        setError('');
        try {
            const user = await signIn(toLower(email), password);
            if (user?.challengeName === 'NEW_PASSWORD_REQUIRED') {
                setLoginStep(LoginSteps.CreateNewPassword);
                setStaffUser(user);
            } else if (user?.challengeName === 'SOFTWARE_TOKEN_MFA') {
                setLoginStep(LoginSteps.SubmitMFA);
                setStaffUser(user);
            } else {
                setLoginStep(LoginSteps.SetupMFA);
                setStaffUser(user);
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            if (err.code === 'NotAuthorizedException') {
                setError('Incorrect email or password');
                setLoading(false);
            } else {
                setError(err.message);
                setLoading(false);
            }
        }
    };

    return (
        <form onSubmit={onSignIn}>
            <Display.VerticalWithSpacing>
                <Logo />
                <Display.VerticalWithSpacing gap={10}>
                    <H2Heading>Staff Login</H2Heading>
                    <span>
                        Not Staff? <Link to="/">Back to simulation</Link>
                    </span>
                </Display.VerticalWithSpacing>
                {loading ? (
                    <Display.HorizontalCenterVerticalCenter>
                        <Spinner height={150} />
                    </Display.HorizontalCenterVerticalCenter>
                ) : (
                    <>
                        <TextInput
                            value={email}
                            validationFailed={!!error}
                            placeholder="Email Address"
                            id="signIn-Username"
                            onChange={({ target }) => setEmail(target.value)}
                        />
                        <PasswordInput
                            value={password}
                            validationFailed={!!error}
                            placeholder="Password"
                            id="signIn-Password"
                            onChange={({ target }) => setPassword(target.value)}
                        />
                        {error && <StyledErrorMessage>{error}</StyledErrorMessage>}
                        <Link to="/admin/reset-password">Forgotten password</Link>
                        <Display.RightAlign>
                            <PrimaryButton disabled={loading || !email || !password} type="submit">
                                Log in
                            </PrimaryButton>
                        </Display.RightAlign>
                    </>
                )}
            </Display.VerticalWithSpacing>
        </form>
    );
};

enum LoginSteps {
    SignIn,
    CreateNewPassword,
    SubmitMFA,
    SetupMFA,
}

export const StaffSignIn = (): React.ReactElement => {
    const { getPreferredMFA, currentAuthenticatedUser: user } = useContext(AuthContext);
    const [staffUser, setStaffUser] = useState<AppUser>();
    const [loginStep, setLoginStep] = useState<LoginSteps>(LoginSteps.SignIn);
    const history = useHistory();

    useEffect(() => {
        (async () => {
            if (user) {
                const mfaSetting = await getPreferredMFA(user);
                // if this is hit we are already logged in and have passed MFA
                if (mfaSetting === 'SOFTWARE_TOKEN_MFA') {
                    history.push('/admin');
                } else {
                    setLoginStep(LoginSteps.SetupMFA);
                    setStaffUser(user);
                }
            }
        })();
    }, [user]);

    return (
        <SplashContainer>
            <SplashHeroCard>
                {loginStep === LoginSteps.SignIn && (
                    <SignInForm setLoginStep={setLoginStep} setStaffUser={setStaffUser} />
                )}
                {loginStep === LoginSteps.CreateNewPassword && staffUser && (
                    <CreateNewPasswordOnFirstSignInForm staffUser={staffUser} setLoginStep={setLoginStep} />
                )}
                {loginStep === LoginSteps.SubmitMFA && staffUser && <SubmitMFAForm staffUser={staffUser} />}
                {loginStep === LoginSteps.SetupMFA && staffUser && <SetupMFAForm staffUser={staffUser} />}
            </SplashHeroCard>
        </SplashContainer>
    );
};
